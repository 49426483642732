<template>
  <v-container
    id="reports"
    fluid
    tag="section"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-chart-bar
      </v-icon> Reports
    </h2>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-card
            class="my-vcard-style"
            color="#ECEFF1"
          >
            <br>
            <h3 class="my-card-title-style">
              Generate Report
            </h3>
            <v-form
              v-model="isFormValid"
            >
              <v-card-text class="text-body-1 text-center">
                <v-container>
                  <v-row>
                    <v-col>
                      <v-select
                        v-model="reportType"
                        :items="['DigiBoard Actions', 'Banner Count']"
                        label="Report Type*"
                        :rules="[(v) => !!v || 'Please select the type of report']"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select
                        v-model="selectedDigiBoard"
                        :items="digiBoards"
                        item-value="location"
                        item-text="location"
                        label="Digi-Board*"
                        :rules="[(v) => !!v || 'Please select a Digi-Board.']"
                        single-line
                        return-object
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="start date"
                      >
                        <v-datetime-picker
                          v-model="start"
                          label="Dispalyed From*"
                          date-format="yyyy-MM-dd"
                          time-format="HH:mm:ss"
                          :text-field-props="{
                            suffix: userTimeZone,
                            errorMessages: errors,
                            class: 'custom-label-color',
                          }"
                          :date-picker-props="{
                            headerColor: '#37474F',
                            max: maxStartDate
                          }"
                          :time-picker-props="{
                            headerColor: '#37474F'
                          }"
                        >
                          <template v-slot:dateIcon>
                            <v-icon
                              color="#37474F"
                              large
                            >
                              mdi-calendar
                            </v-icon>
                          </template>
                          <template v-slot:timeIcon>
                            <v-icon
                              color="#37474F"
                              large
                            >
                              mdi-alarm
                            </v-icon>
                          </template>
                        </v-datetime-picker>
                      </validation-provider>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-datetime-picker
                        v-model="end"
                        label="Displayed To"
                        :text-field-props="{
                          persistentHint: true,
                          suffix: userTimeZone,
                        }"
                        :date-picker-props="{
                          headerColor: '#37474F',
                          min: minEndDate,
                          max: maxEndDate
                        }"
                        :time-picker-props="{
                          headerColor: '#37474F'
                        }"
                      >
                        <template v-slot:dateIcon>
                          <v-icon
                            color="#37474F"
                            large
                          >
                            mdi-calendar
                          </v-icon>
                        </template>
                        <template v-slot:timeIcon>
                          <v-icon
                            color="#37474F"
                            large
                          >
                            mdi-alarm
                          </v-icon>
                        </template>
                      </v-datetime-picker>
                    </v-col>
                  </v-row>
                </v-container>
                <p class="text-sm-left">
                  *Indicates required field
                </p>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  class="mt-2"
                  color="#FF3700"
                  :disabled="!isFormComplete"
                  @click="submit()"
                >
                  Generate
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <report-graph />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
        >
          <report-summary />
        </v-col>
      </v-row>
      <centre-spinner
        :loading="spinnerLoading"
      />
    </v-card>
  </v-container>
</template>

<script>
  import ReportGraph from 'src/views/dashboard/component/report/ReportGraph';
  import ReportSummary from 'src/views/dashboard/component/report/ReportSummary';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import moment from 'moment-timezone';
  import Constants from 'src/constants';
  import ModuleHelper from 'src/helpers/module-helper';

  export default {
    name: 'Reports',
    components: {
      ReportGraph,
      ReportSummary,
      'centre-spinner': spinner,
    },
    data: () => ({
      selectedDigiBoard: {},
      reportType: '',
      start: '',
      end: '',
      isFormValid: false,
      spinnerLoading: false,
      helper: new ModuleHelper(),
    }),
    beforeRouteEnter (to, from, next) {
      next(vm => {
        if (vm.isPlanExpired === true || vm.helper.isModuleExist(Constants.MODULE_REPORT) === false) {
          vm.$router.push({ name: 'Dashboard' });
        } else {
          next();
        }
      });
    },
    computed: {
      minEndDate () {
        return moment(this.start).format('YYYY-MM-DD');
      },
      maxEndDate () {
        return moment().subtract(1, 'days').format('YYYY-MM-DD');
      },
      maxStartDate () {
        return moment().subtract(1, 'days').format('YYYY-MM-DD');
      },
      isFormComplete () {
        return this.reportType && this.selectedDigiBoard && this.start && this.end;
      },
      digiBoards () {
        return this.$store.getters['digiboards/getDigiBoards'];
      },
      userTimeZone () {
        var Zone = moment.tz.guess();
        var timezone = moment.tz(Zone).zoneAbbr();
        return timezone;
      },
      organizationDetails () {
        return this.$store.getters['userprofile/getOrganizationDetails'];
      },
      isPlanExpired () {
        if (Object.keys(this.organizationDetails).length > 0 && this.organizationDetails.subscription_status === 'expired') {
          return true;
        }
        return false;
      },
    },
    watch: {
      isFormValid: function (value) {
        if (value === false) {
          this.$store.dispatch('reports/onCurrentFormData', {});
          this.$store.dispatch('reports/onCurrentGraphData', []);
        }
      },
    },
    mounted () {
      this.$store.dispatch('digiboards/fetchDigiBoards');
    },
    methods: {
      convertToGmt (date) {
        return moment(date).utc();
      },
      getQuery () {
        var fieldName = null;
        switch (this.reportType) {
          case 'DigiBoard Actions':
            fieldName = 'message.action.keyword';
            break;
          case 'Banner Count':
            fieldName = 'message.banner_id.keyword';
            break;
        }
        return {
          query: {
            index: this.selectedDigiBoard.external_id.toLowerCase(),
            size: 0,
            body: {
              query: {
                range: {
                  happendAt: {
                    gte: this.convertToGmt(this.start),
                    lte: this.convertToGmt(this.end),
                  },
                },
              },
              aggs: {
                attribCount: {
                  terms: {
                    field: fieldName,
                  },
                },
              },
            },
          },
        };
      },
      async submit () {
        this.spinnerLoading = true;
        this.$store.dispatch('reports/onCurrentFormData', {
          reportType: this.reportType,
          digiLocation: this.selectedDigiBoard.location,
          start: this.start,
          end: this.end,
        });
        await this.$store.dispatch('reports/fetchGraphData', this.getQuery(this.reportType));
        this.spinnerLoading = false;
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-vcard-style {
  border-radius: 15px;
  margin-left: 20px;
}
.my-card-title-style {
  color: #37474F;
  font-size: 18px;
  text-align: center;
}
</style>
